<!-- =========================================================================================
    File Name: RadioDefault.vue
    Description: Rendering default radio
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <section v-if="surveyLoaded && survey && survey.length">
    <div class="imageLogo">
      <img :src="logo" />
    </div>
    <vx-card id="survey" title="Encuesta">
      <div v-if="alreadySent || surveyExpired" id="absOverlay"></div>
      <section
        v-bind:class="{ 'text-red': surveyErrors[entry.key] }"
        v-for="(entry, index) in survey"
        :key="index"
      >
        <div v-if="!entry.hidden">
          <div v-for="(question, indexQ) in entry.question" :key="indexQ">
            <p class="title-section" v-if="entry.finish">
              <span>
                <strong>{{ question }}</strong>
              </span>
            </p>
            <p v-else class="title-section">
              <span v-if="indexQ == 0" class="number-section"
                >{{ index + 1 }}.</span
              >
              {{ question }}
            </p>
          </div>
          <div v-if="entry.responseType == 1">
            <TextInput
              :key="keyReset + index"
              :counterDanger.sync="counterDanger[entry.key]"
              :value.sync="surveyResponse[entry.key]"
              :update="(val) => update(entry.key, val)"
              @change="checkErrors"
            />
          </div>
          <div v-if="entry.responseType == 2">
            <NumberInput
              :key="keyReset + index"
              :value.sync="surveyResponse[entry.key]"
              :update="(val) => update(entry.key, val)"
              @change="checkErrors"
            />
          </div>
          <div v-if="entry.responseType == 3">
            <div v-if="isNumeric(entry.options)">
              <RatingList
                :key="keyReset + index"
                :choices="entry.options"
                :value.sync="surveyResponse[entry.key]"
                :update="(val) => update(entry.key, val)"
                @change="checkErrors"
              />
            </div>
            <div v-else>
              <SelectList
                :key="keyReset + index"
                :choices="entry.options"
                :value.sync="surveyResponse[entry.key]"
                :update="(val) => update(entry.key, val)"
                @change="checkErrors"
              />
            </div>
          </div>
          <div v-if="surveyErrors[entry.key]">
            <vs-alert
              :key="index"
              color="danger"
              :active.sync="surveyErrors[entry.key]"
            >
              <span>{{ surveyErrors[entry.key] }}.</span>
            </vs-alert>
          </div>
        </div>
      </section>
      <div slot="footer">
        <vs-alert
          v-if="alreadySent"
          class="my-3 success_survey"
          active="true"
          color="success"
        >
          Encuesta finalizada
        </vs-alert>
        <vs-alert
          v-if="surveyExpired && !alreadySent"
          class="my-3 success_survey"
          active="true"
          color="warning"
        >
          {{ surveyExpirationMessage }}
        </vs-alert>
        <vs-row vs-justify="flex-end">
          <!-- <vs-button @click.prevent="cleanSurvey()" :color="this.color">Reiniciar</vs-button> -->
          <vs-button
            v-if="!surveyExpired && !alreadySent"
            :disabled="loading || alreadySent || !done"
            @click="sendSurvey()"
            color="primary"
          >
            Enviar
          </vs-button>
        </vs-row>
      </div>
    </vx-card>
    <div v-if="finalMsg && finalMsg.question">
      <PopupSuccess
        v-if="showSuccessPopup"
        :active.sync="showSuccessPopup"
        :questions="finalMsg.question"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
/**
 * Url test: ?senderId=ffbf9435-7095-45d6-88bf-a2dfa62afe37&botService=5ec82ee6682c810013b2ec3d&surveyService=5eece6977267ee00130fd7a4
 *
 */

import evalConditionsArray from "./utils";

export default {
  data() {
    return {
      alreadySent: true,
      keyReset: new Date().getTime(),
      loading: false,
      showSuccessPopup: false,
      surveyResponse: {},
      surveyPreviousResponse: {},
      surveyErrors: {},
      counterDanger: {},
      logo: "",
      surveyLoaded: false,
      surveyExpired: false,
      surveyExpirationMessage: "",
      senderId: null,
      botService: null,
      surveyService: null,

      done: false,
    };
  },
  components: {
    PopupSuccess: () => import("./components/popup.vue"),
    TextInput: () => import("./components/text.vue"),
    RatingList: () => import("./components/ratingList.vue"),
    SelectList: () => import("./components/choices.vue"),
    NumberInput: () => import("./components/number.vue"),
  },
  beforeMount() {
    this.init();
  },
  watch: {
    "getSurveyTheme.companyLogo"(newVal) {
      // console.log("CHANGED LOGO::", newVal);
      if (newVal) {
        this.logo = newVal;
      }
    },
    "getSurveyTheme.colors"(newVal) {
      this.$vs.theme(newVal);
    },
  },
  computed: {
    ...mapGetters("survey", ["getSurvey", "getFinalMessage", "getSurveyTheme"]),
    survey() {
      const result = [];
      let hidden = false;
      let jump = null;
      const finalGroup = this.getFinalMessage.group;
      this.done = false;
      this.getSurvey.forEach((item, index) => {
        const aux = { ...item };
        aux.hidden = hidden;
        if (
          index === this.getSurvey.length - 1 &&
          this.surveyResponse[aux.key]
        ) {
          this.done = true;
        }
        if (
          !hidden &&
          aux.conditions.length > 0 &&
          !this.surveyResponse[aux.key]
        ) {
          hidden = true;
          jump = null;
        } else if (
          !hidden &&
          aux.conditions.length > 0 &&
          this.surveyResponse[aux.key]
        ) {
          const jumpToIndex = evalConditionsArray(
            aux.conditions,
            this.surveyResponse[aux.key].toString()
          );
          if (jumpToIndex > -1) {
            jump = aux.conditions[jumpToIndex].jump;
            hidden = true;
            if (jump.group === finalGroup) {
              this.done = true;
            }
          } else {
            jump = null;
            hidden = false;
          }
        } else if (
          hidden &&
          jump &&
          jump.group === aux.group &&
          (!jump.name || jump.name === aux.name)
        ) {
          aux.hidden = false;
          jump = null;
          if (aux.conditions.length > 0 && !this.surveyResponse[aux.key]) {
            hidden = true;
            jump = null;
          } else if (
            aux.conditions.length > 0 &&
            this.surveyResponse[aux.key]
          ) {
            const jumpToIndex = evalConditionsArray(
              aux.conditions,
              this.surveyResponse[aux.key].toString()
            );
            if (jumpToIndex > -1) {
              jump = aux.conditions[jumpToIndex].jump;
              hidden = true;
              if (jump.group === finalGroup) {
                this.done = true;
              }
            } else {
              jump = null;
              hidden = false;
            }
          } else {
            hidden = false;
          }
        }
        result.push(aux);
      });
      return result;
    },
    finalMsg() {
      return this.getFinalMessage;
    },
  },
  methods: {
    ...mapActions("survey", [
      "fetchUniqid",
      "fetchSurveyById",
      "setupSurveyParams",
      "sendSurveyResponse",
      "fetchSurveySettings",
    ]),
    update(key, val) {
      this.surveyResponse = JSON.parse(JSON.stringify(this.surveyResponse));
    },
    async init() {
      try {
        await this.loadParams();
      } catch (ex) {
        console.error(ex);
      }
      if (!this.surveyService || this.surveyService.length !== 24) {
        this.$router.push({ name: "page-error-404" }).catch((e) => {});
      } else {
        this.loadSettings({
          surveyId: this.surveyService, // no es el _id pero el endpoint lo banca
          senderId: this.senderId,
          botService: this.botService,
          surveyService: this.surveyService,
        });
        const uniqid = this.$route.params.uniqid || undefined;
        this.fetchSurveyById({
          surveyId: this.surveyService, // no es el _id pero el endpoint lo banca
          senderId: this.senderId,
          botService: this.botService,
          surveyService: this.surveyService,
          uniqid,
        })
          .then((res) => {
            // console.log("RESPONSE::", res);
            this.survey.forEach(({ key }) => {
              if (key !== undefined)
                this.surveyResponse[key] = this.surveyPreviousResponse[key]
                  ? this.surveyPreviousResponse[key]
                  : "";
            });
            this.surveyLoaded = true;
            console.log("survey response", this.surveyResponse);
            this.keyReset = new Date().getTime();
          })
          .catch((e) => {
            console.error(e);
            let route = "page-error-404";
            if (e.response && e.response.status) {
              const status = e.response.status;
              switch (status) {
                case 500:
                  route = "page-error-500";
                  break;
                case 404:
                  route = "page-error-404";
                  break;
                default:
                  break;
              }
            }
            this.$router.push({ name: route }).catch((e) => {});
          });
      }
    },
    loadSettings(params) {
      this.fetchSurveySettings(params)
        .then((res) => {
          // console.log("RESPONSE::", res.data);
        })
        .catch((e) => {
          console.error("Error Survey Settings::", e.message);
        });
    },
    async loadParams() {
      // const error = false;
      // if (!this.senderId || !this.botService || !this.surveyService) {
      //   this.$router.push({ name: "page-error-404" }).catch((e) => {});
      //   return;
      // }
      const uniqid = this.$route.params.uniqid;
      const result = await this.fetchUniqid({ uniqid });

      if(result.data && result.data.surveyExpirationAt && new Date()>new Date(result.data.surveyExpirationAt)){
        this.surveyExpired = true;
        this.surveyExpirationMessage = result.data.surveyExpirationMessage || "No puedes responder esta encuesta porque finalizó"
      }

      if (
        result.data &&
        result.data.senderId &&
        result.data.botService &&
        result.data.surveyService
      ) {
        this.senderId = result.data.senderId;
        this.botService = result.data.botService;
        this.surveyService = result.data.surveyService;
      }
      if (result.data.data && result.data.data.length) {
        result.data.data.forEach((item, index) => {
          const { key, value } = item;
          this.surveyPreviousResponse[key] = value;
          this.$set(this.surveyResponse, key, value);
          this.alreadySent = true;
        });
      } else {
        this.alreadySent = false;
      }
      if (result.data.data && result.data.data.length) {
        result.data.data.forEach((item, index) => {
          const { key, value } = item;
          this.surveyPreviousResponse[key] = value;
          this.$set(this.surveyResponse, key, value);
          this.alreadySent = true;
        });
      } else {
        this.alreadySent = false;
      }

      // console.log("LARGO::", this.surveyService.length);
      this.setupSurveyParams({
        senderId: this.senderId,
        botService: this.botService,
        surveyService: this.surveyService,
      });
      const params = {
        senderId: this.senderId,
        botService: this.botService,
        surveyService: this.surveyService,
      };
    },
    checkErrors() {
      this.$nextTick(() => {
        // console.log("CHECK ERRORS", this.surveyResponse);
        const result = this.surveyResponse;
        const keys = Object.keys(result);
        keys.forEach((key, index) => {
          const value = result[key];
          const surveyItem = this.survey[index];
          // console.log("VALUE::", value, key);
          if (value) {
            // console.log("SET SURVEY ERRORS");
            this.$set(this.surveyErrors, key, "");
          }
        });
      });
    },
    cleanSurvey() {
      const result = this.surveyResponse;
      const keys = Object.keys(result);
      keys.forEach((key, index) => {
        this.surveyResponse[key] = "";
        this.$set(this.surveyResponse, key, "");
      });
      // console.log(this.surveyResponse);
      this.keyReset = new Date().getTime();
    },
    getError(responseType, counter = false) {
      let errors = {
        1: "Este campo no puede estar vacío",
        2: "Debe elegir un número",
        3: "Debe elegir al menos una opción",
      };
      if (counter) {
        errors = {
          1: "Texto no puede exceder los 4096 caractéres",
        };
      }

      return errors[responseType];
    },
    sendSurvey() {
      const result = this.surveyResponse;
      // const keys = Object.keys(result);
      const keys = this.survey
        .filter((item) => !item.hidden)
        .map((item) => item.key);
      this.surveyErrors = {};
      let error = false;
      // console.log("COUNTER KEYS::", this.counterDanger);
      console.log(this.surveyErrors);
      keys.forEach((key, index) => {
        if (key) {
          const value = result[key];
          const surveyItem = this.survey[index];
          if (!value) {
            error = true;
            if (!this.surveyErrors[key]) {
              //this.surveyErrors[key] = this.getError(surveyItem.responseType);
              this.$set(
                this.surveyErrors,
                key,
                this.getError(surveyItem.responseType)
              );
            }
          } else if (this.counterDanger[key] == true) {
            error = true;
            this.$set(
              this.surveyErrors,
              key,
              this.getError(surveyItem.responseType, true)
            );
          } else {
            this.$set(this.surveyErrors, key, "");
          }
        }
      });

      if (!error) {
        // console.log("NO ERRORS::", this.surveyErrors);
        this.loading = true;
        const finalResult = Object.keys(result).map((key) => {
          const value = result[key];
          return {
            key,
            input: value.toString().trim(),
          };
        });
        this.sendSurveyResponse(finalResult)
          .then((res) => {
            this.showSuccessPopup = true;
            this.cleanSurvey();
            //this.$vs.notify({ title: 'Success', text: 'Respuesta enviada', color: 'success' })
          })
          .catch((e) => {
            let msg = "";
            if (e.response && e.response.data) {
              msg = JSON.stringify(e.response.data);
            } else {
              msg = e.message;
            }
            this.$vs.notify({ title: "Error", text: msg, color: "danger" });
            console.error(e);
          })
          .finally(async (e) => {
            await this.init();
            this.loading = false;
          })
          .finally(async (e) => {
            await this.init();
            this.loading = false;
          });
      }
    },
    acceptAlert() {},
    isNumeric(options) {
      if (options && options.length) {
        let lastEl = null;
        return options.every((el) => {
          const num = parseInt(el);
          const check = isNaN(num);
          if (check) return false;
          if (lastEl !== null) {
            const toReturn = num > lastEl + 0;
            lastEl = num + 0;
            return toReturn;
          } else {
            lastEl = num + 0;
            return true;
          }
        });
      }
    },
  },
};
</script>

<style lang="css">
ul.rating-list {
  border: 1px solid rgb(var(--vs-primary));
}
#survey ul.rating-list .items {
  display: flex;
  flex-grow: 1;
}
@media (max-width: 768px) {
  #survey ul.rating-list {
    border-left: 1px solid rgb(var(--vs-primary)) !important;
    border-top: 1px solid rgb(var(--vs-primary)) !important;
    border-radius: none;
  }
  #survey ul.rating-list li {
    box-shadow: 0.2px 0.2px 0.2px 0.2px rgb(var(--vs-primary));
  }
}

@media (max-width: 300px) {
  #survey ul.rating-list li {
    font-size: 12px;
  }
}
</style>

<style lang="scss">
.navbar-floating .router-content {
  margin-top: 0;
}
.imageLogo {
  margin-bottom: 30px;
  display: flex;
  width: 100%;
}
.imageLogo img {
  width: 320px;
  margin: auto;
  object-fit: contain;
  max-height: 100px;
  max-width: 65vw;
  height: auto;
}
#survey {
  max-width: 900px;
  margin: auto;
  section {
    padding-top: 30px;
    &.text-red {
      p {
        color: rgba(var(--vs-danger), 1);
      }
      .vs-con-textarea,
      .vs-radio--borde {
        border-color: rgba(var(--vs-danger), 1) !important;
      }
    }
  }
  .rating-list-content {
    padding-top: 12px;
    width: 100%;
  }
  .rating-list-overflow {
    max-width: 700px;
    width: 100%;
    //overflow-x: auto;
  }
  ul.rating-list {
    position: relative;
    background: #62626236;
    .rating-span {
      position: absolute;
      top: -1.5em;
      font-size: 0.85em;
      opacity: 0.8;
      &.start {
        left: 0;
      }
      &.end {
        right: 0;
      }
    }
    // margin-top: 25px;
    margin-bottom: 15px;
    border-radius: 5px;
    li:last-child {
      .con-vs-radio {
        border-right: 0 !important;
      }
    }
  }
  .rating-list {
    max-width: 700px;
    min-width: 450px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .number-section {
    margin-right: 5px;
  }
  .title-section {
    font-size: 1.1em;
  }
  .radio-list {
    max-width: 700px;
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    li .vs-radio--label {
      margin: 12px 0;
      margin-left: 10px;
    }
  }
}
@media (max-width: 768px) {
  #survey ul.rating-list {
    .rating-span.end {
      right: 0;
      top: calc(100% + 1px);
      left: 0;
    }
    background: white;
    border-radius: 0;
    min-width: 0 !important;
    border: none;
    .ratingBtn {
      border-right: none;
    }
    li {
      background: #62626236;
      width: calc(100% / 4) !important;
    }
  }
  #survey {
    .radio-list {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .imageLogo {
    margin-bottom: 20px !important;
  }
}
#absOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.success_survey {
  height: fit-content !important;
}
</style>
