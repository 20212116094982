/* eslint-disable init-declarations */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable camelcase */

function aux_length_compare (params, method) {
  if (
    params.type === 'ctx&input'
    && params.arg0
    && params.data
    && params.data.context
    && params.data.context.data
    && params.data.context.data[params.arg0]
    && params.data.input
    && isNumber(params.data.input)
  ) {
    return method(
      params.data.context.data[params.arg0].length,
      parseInt(params.data.input, 10)
    )
  }
  if (
    params.type === 'ctx&ctx'
    && params.arg0
    && params.arg1
    && params.data
    && params.data.context
    && params.data.context.data
    && params.data.context.data[params.arg0]
    && params.data.context.data[params.arg1]
    && params.data.context.data[params.arg1].length > 0
    && isNumber(params.data.context.data[params.arg1][params.data.context.data[params.arg1].length - 1])
  ) {
    return method(
      params.data.context.data[params.arg0].length,
      parseInt(params.data.context.data[params.arg1][params.data.context.data[params.arg1].length - 1], 10)
    )
  }
  if (
    // params.type === 'ctx&var' <-- por defecto asumimos que es este
    params.arg0
    && params.arg1
    && params.data
    && params.data.context
    && params.data.context.data
    && params.data.context.data[params.arg0]
    && isNumber(params.arg1)
  ) {
    return method(
      params.data.context.data[params.arg0].length,
      parseInt(params.arg1, 10)
    )
  }
  return false
}

function aux_contains (params, method) {
  if (
    params.type === 'ctx&input'
    && params.arg0
    && params.data
    && params.data.context
    && params.data.context.data
    && params.data.context.data[params.arg0]
    && params.data.context.data[params.arg0].length > 0
    && params.data.input
  ) {
    return method(params.data.context.data[params.arg0], params.data.input)
  }
  if (
    params.type === 'ctx&ctx'
    && params.arg0
    && params.arg1
    && params.data
    && params.data.context
    && params.data.context.data
    && params.data.context.data[params.arg0]
    && params.data.context.data[params.arg0].length > 0
    && params.data.context.data[params.arg1]
    && params.data.context.data[params.arg1].length > 0
  ) {
    return method(
      params.data.context.data[params.arg0],
      params.data.context.data[params.arg1][params.data.context.data[params.arg1].length - 1]
    )
  }
  if (
    params.type === 'ctx&var'
    && params.arg0
    && params.arg1
    && params.data
    && params.data.context
    && params.data.context.data
    && params.data.context.data[params.arg0]
    && params.data.context.data[params.arg0].length > 0
  ) {
    return method(
      params.data.context.data[params.arg0],
      params.arg1
    )
  }
  return false
}

function aux_compare (params, method) {
  if (
    params.type === 'ctx&input'
    && params.arg0
    && params.data
    && params.data.context
    && params.data.context.data
    && params.data.context.data[params.arg0]
    && params.data.context.data[params.arg0].length > 0
    && params.data.input
  ) {
    return method(params.data.context.data[params.arg0][params.data.context.data[params.arg0].length - 1], params.data.input)
  }
  if (
    params.type === 'ctx&ctx'
    && params.arg0
    && params.arg1
    && params.data
    && params.data.context
    && params.data.context.data
    && params.data.context.data[params.arg0]
    && params.data.context.data[params.arg0].length > 0
    && params.data.context.data[params.arg1]
    && params.data.context.data[params.arg1].length > 0
  ) {
    if (
      isNumber(params.data.context.data[params.arg0][params.data.context.data[params.arg0].length - 1])
      && isNumber(params.data.context.data[params.arg1][params.data.context.data[params.arg1].length - 1])
    ) {
      const arg0 = parseFloat(params.data.context.data[params.arg0][params.data.context.data[params.arg0].length - 1])
      const arg1 = parseFloat(params.data.context.data[params.arg1][params.data.context.data[params.arg1].length - 1])
      return method(arg0, arg1)
    }
    return method(
      params.data.context.data[params.arg0][params.data.context.data[params.arg0].length - 1],
      params.data.context.data[params.arg1][params.data.context.data[params.arg1].length - 1]
    )
  }
  if (
    params.type === 'ctx&var'
    && params.arg0
    && params.arg1
    && params.data
    && params.data.context
    && params.data.context.data
    && params.data.context.data[params.arg0]
    && params.data.context.data[params.arg0].length > 0
  ) {
    if (
      isNumber(params.data.context.data[params.arg0][params.data.context.data[params.arg0].length - 1])
      && isNumber(params.arg1)
    ) {
      const arg0 = parseFloat(params.data.context.data[params.arg0][params.data.context.data[params.arg0].length - 1])
      const arg1 = parseFloat(params.arg1)
      return method(arg0, arg1)
    }
    return method(
      params.data.context.data[params.arg0][params.data.context.data[params.arg0].length - 1],
      params.arg1
    )
  }
  if (
    params.type === 'var&input'
    && params.arg0
    && params.data.input
  ) {
    return method(params.arg0, params.data.input)
  }
  if (
    params.type === 'input&var'
    && params.arg0
    && params.data.input
  ) {
    return method(params.data.input, params.arg0)
  }
  return false
}

const funcs = {
  true: () => true,
  false: () => false,
  // params = { data, intent, confidence (opcional) }
  intent: params => params.data
    && params.intent
    && params.data.intents
    && Array.isArray(params.data.intents)
    && (params.data.intents.map(element => element.intent)).indexOf(params.intent) > -1
    && (
      typeof params.confidence !== 'number'
      || params.data.intents.some(element => element.intent === params.intent
        && element.confidence >= params.confidence)
    ),
  // params = { data, entity, confidence (opcional), value (opcional) }
  entity: params => params.data
    && params.entity
    && params.data.entities
    && Array.isArray(params.data.entities)
    && (params.data.entities.map(element => element.entity)).indexOf(params.entity) > -1
    && (
      typeof params.confidence !== 'number'
      || params.data.entities.some(element => element.entity === params.entity
        && element.confidence >= params.confidence)
    )
    && (
      typeof params.value !== 'string'
      || params.value.length === 0
      || params.data.entities.some(element => element.entity === params.entity
        && (!element.value || element.value === params.value))
    ),
  match: (params) => {
    if (!params || !params.data || !params.data.input || !params.regex) {
      return false
    }
    let _r
    // Observación: la regex debería ser válida porque debería ser validada en el frontend,
    // pero por las dudas.
    try {
      if (
        params.data.context
        && (
          params.data.context.survey
          || params.data.context.validation
          || params.data.context.option
        )
      ) {
        // Si estamos en una encuesta las RegExp son case insnesitive.
        // TODO: Está medio desprolijo, mejorar en un futuro.
        _r = new RegExp(params.regex, 'i')
      } else {
        _r = new RegExp(params.regex)
      }
      return _r.test(params.data.input)
    } catch (_) {
      return false
    }
  },
  // for context variables
  // exists
  exists: (params) => {
    if (
      params.arg0
      && params.data
      && params.data.context
      && params.data.context.data
      && params.data.context.data[params.arg0]
      && params.data.context.data[params.arg0].length > 0
    ) {
      return true
    }
    return false
  },
  notExists: (params) => {
    if (
      params.arg0
      && params.data
      && params.data.context
      && params.data.context.data
      && params.data.context.data[params.arg0]
      && params.data.context.data[params.arg0].length > 0
    ) {
      return false
    }
    return true
  },
  // contains
  contains: params => aux_contains(params, (arg0, arg1) => (arg0 || []).findIndex(
    item => arg1.trim().toLowerCase() === item.trim().toLowerCase()
  ) > -1),
  containsNumber: params => aux_contains(params, (arg0, arg1) => (arg0 || []).findIndex(
    item => arg1.replace(/\D/g, '') === item.replace(/\D/g, '') && arg1.replace(/\D/g, '') !== ''
  ) > -1),
  containsIndex: params => aux_contains(
    params,
    (arg0, arg1) => parseInt(arg1.replace(/\D/g, ''), 10) > 0
      && typeof (arg0 || [])[parseInt(arg1.replace(/\D/g, ''), 10) - 1] === 'string'
  ),
  // compare
  equal: params => aux_compare(params, (arg0, arg1) => arg0 === arg1),
  notEqual: params => aux_compare(params, (arg0, arg1) => arg0 !== arg1),
  lessThan: params => aux_compare(params, (arg0, arg1) => !isNaN(parseFloat(arg0))
    && !isNaN(parseFloat(arg1))
    && parseFloat(arg0) < parseFloat(arg1)),
  lessThanOrEqual: params => aux_compare(params, (arg0, arg1) => !isNaN(parseFloat(arg0))
    && !isNaN(parseFloat(arg1))
    && parseFloat(arg0) <= parseFloat(arg1)),
  greaterThan: params => aux_compare(params, (arg0, arg1) => !isNaN(parseFloat(arg0))
    && !isNaN(parseFloat(arg1))
    && parseFloat(arg0) > parseFloat(arg1)),
  greaterThanOrEqual: params => aux_compare(params, (arg0, arg1) => !isNaN(parseFloat(arg0))
    && !isNaN(parseFloat(arg1))
    && parseFloat(arg0) >= parseFloat(arg1)),
  // length
  lengthEqual: params => aux_length_compare(params, (arg0, arg1) => arg0 === arg1),
  lengthNotEqual: params => aux_length_compare(params, (arg0, arg1) => arg0 !== arg1),
  lengthLessThan: params => aux_length_compare(params, (arg0, arg1) => arg0 < arg1),
  lengthLessThanOrEqual: params => aux_length_compare(params, (arg0, arg1) => arg0 <= arg1),
  lengthGreaterThan: params => aux_length_compare(params, (arg0, arg1) => arg0 > arg1),
  lengthGreaterThanOrEqual: params => aux_length_compare(params, (arg0, arg1) => arg0 >= arg1)
}

function evalConditionsArray (conditions, input) {
  return conditions.findIndex((cond) => {
    const { func, args } = cond
    const params = {
      data: {
        input,
        intents: [],
        entities: [],
        context: {
          data: {}
        }
      }
    }
    if (func === 'match') {
      // eslint-disable-next-line prefer-destructuring
      params.regex = args[0]
    } else {
      // eslint-disable-next-line prefer-destructuring
      params.arg0 = args[0]
      params.type = 'input&var'
    }
    return funcs[func](params)
  })
}

export default evalConditionsArray

// module.exports = {
//   evalConditionsArray
// }
